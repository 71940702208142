import { Fragment, useEffect, useState } from "react";
import http from "./services/http";
import { ChartBarSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Page from "./layout/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { ISODateToHuman } from "./ProspectPage";
import { parseDate, toIsoDateOrNull } from "./date";
import qs from "qs";
import SelectAll from "./components/SelectAll";
import Label from "./components/Label";
import SelectWithRange, {
  RangeOptionValue,
} from "./components/SelectWithRange";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { ProspectStatus } from "./types";

interface StatAcquisition {
  name: string;
  nb_prospects: number;
  nb_new: number;
  nb_known: number;
  nb_unsubscribed: number;
  prospects_ids: string[];
  children: {
    [name: string]: {
      name: string;
      nb_prospects: number;
      nb_new: number;
      nb_known: number;
      nb_unsubscribed: number;
      prospects_ids: string[];
    };
  };
}

interface IProspect {
  id: string;
  email: string;
  created_at: string;
  date_source: string | null;
  date_pipe: string | null;
  status: ProspectStatus;
}

interface IStatsSources {
  sources: StatAcquisition[];
  pipes: StatAcquisition[];
  prospects: {
    [id: string]: IProspect;
  };
}

function getProspectStatus(status: ProspectStatus) {
  const statusI18n = {
    NEW: "nouveau",
    KNOWN: "connu",
    UNSUBSCRIBED: "désinscrit",
  };
  return statusI18n[status];
}

function getProspectBGStatusColor(status: ProspectStatus) {
  const statusI18n = {
    NEW: "bg-green-100",
    KNOWN: "bg-blue-100",
    UNSUBSCRIBED: "bg-orange-100",
  };
  return statusI18n[status];
}
function getProspectTextStatusColor(status: ProspectStatus) {
  const statusI18n = {
    NEW: "text-green-800",
    KNOWN: "text-blue-800",
    UNSUBSCRIBED: "text-orange-800",
  };
  return statusI18n[status];
}

function buildParams({
  acquisitions,
  rangeOption,
  seePipeInSource,
  acquisitionsFrom,
  acquisitionsTo,
}: {
  acquisitions: string[];
  seePipeInSource: boolean;
  rangeOption: RangeOptionValue;
  acquisitionsFrom: Date | null;
  acquisitionsTo: Date | null;
}) {
  return {
    acquisitions,
    rangeOption,
    seePipeInSource: seePipeInSource ? "true" : "false",
    acquisitions_from: toIsoDateOrNull(acquisitionsFrom),
    acquisitions_to: toIsoDateOrNull(acquisitionsTo),
  };
}

function StatDetails({
  prospects,
  seeDetails,
  setSeeDetails,
}: {
  prospects: IProspect[];
  seeDetails: boolean;
  setSeeDetails: (seeDetails: boolean) => void;
}) {
  return (
    <Transition.Root show={seeDetails} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setSeeDetails(false)}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Détails
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => setSeeDetails(false)}
                          >
                            <span className="sr-only">Fermer</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {prospects.length !== 0 && (
                        <div className="mt-5">
                          <table className="min-w-full bg-white">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Email
                                </th>
                                <th
                                  scope="col"
                                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Status
                                </th>
                                <th
                                  scope="col"
                                  className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  Date d'acquisition
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {prospects
                                .sort((a, b) => {
                                  const statusOrder = {
                                    NEW: 0,
                                    KNOWN: 1,
                                    UNSUBSCRIBED: 2,
                                  };

                                  if (
                                    statusOrder[a.status] <
                                    statusOrder[b.status]
                                  ) {
                                    return -1;
                                  }
                                  if (
                                    statusOrder[a.status] >
                                    statusOrder[b.status]
                                  ) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map((prospect) => (
                                  <tr>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {
                                        <Link
                                          to={`/prospect?email=${prospect.email}`}
                                          className="text-blue-500 hover:underline flex items-center gap-1"
                                        >
                                          {prospect.email}
                                        </Link>
                                      }
                                    </td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      <span
                                        className={`inline-flex items-center rounded-full ${getProspectBGStatusColor(
                                          prospect.status
                                        )} px-2.5 py-0.5 text-xs font-medium ${getProspectTextStatusColor(
                                          prospect.status
                                        )} ml-2`}
                                      >
                                        {getProspectStatus(prospect.status)}
                                      </span>
                                    </td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {prospect.date_source &&
                                        ISODateToHuman(prospect.date_source)}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default function PageAcquisitions2() {
  const navigate = useNavigate();
  const location = useLocation();
  const filters = {
    acquisitions: ["all"],
    acquisitions_from: null,
    acquisitions_to: null,
    seePipeInSource: "true",
    rangeOption: "previousWeek",
    ...qs.parse(location.search, { ignoreQueryPrefix: true }),
  };
  const [acquisitions, setAcquisitions] = useState<string[]>(
    filters.acquisitions
  );
  const [acquisitionsFrom, setAcquisitionsFrom] = useState<Date | null>(
    parseDate(filters.acquisitions_from)
  );
  const [acquisitionsTo, setAcquisitionsTo] = useState<Date | null>(
    parseDate(filters.acquisitions_to)
  );
  const [statsSources, setStatsSources] = useState<IStatsSources | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [prospects, setProspects] = useState<IProspect[]>([]);
  const [seeProspectDetails, setSeeProspectDetails] = useState(false);
  const [seePipeInSource, setSeePipeInSource] = useState(
    filters.seePipeInSource === "true"
  );
  const [rangeOption, setRangeOption] = useState<RangeOptionValue>(
    filters.rangeOption as RangeOptionValue
  );
  const key = seePipeInSource ? "sources" : "pipes";

  useEffect(() => {
    const params = buildParams({
      acquisitions,
      seePipeInSource,
      rangeOption,
      acquisitionsFrom,
      acquisitionsTo,
    });
    navigate(
      `/stats_sources_pipes?${qs.stringify(params, {
        arrayFormat: "brackets",
        skipNulls: true,
        encode: false,
      })}`
    );
  }, [
    navigate,
    acquisitions,
    acquisitionsFrom,
    acquisitionsTo,
    rangeOption,
    seePipeInSource,
  ]);

  return (
    <Page title="ROI Hunter">
      <StatDetails
        prospects={prospects}
        seeDetails={seeProspectDetails}
        setSeeDetails={setSeeProspectDetails}
      />
      <div className="px-4 py-5 sm:p-0">
        <div className="p-5 grid gap-x-4 grid-cols-3 bg-white">
          <div>
            <Label label="Acquisitions" htmlFor="acquisitions" />
            <SelectAll
              id="acquisitions"
              onLoad={() =>
                http
                  .get("/acquisitions/")
                  .then((response) => response.data as string[])
              }
              selectedElements={acquisitions}
              setSelectedElements={setAcquisitions}
            />
          </div>
          <div>
            <Label label="Période" htmlFor="acquisitionsRange" />
            <SelectWithRange
              id="acquisitionsRange"
              value={rangeOption}
              setValue={setRangeOption}
              startDate={acquisitionsFrom}
              setStartDate={setAcquisitionsFrom}
              endDate={acquisitionsTo}
              setEndDate={setAcquisitionsTo}
            />
          </div>
          <div>
            <Label label="Ordre" htmlFor="acquisitionsRange" />
            <Switch.Group as="div" className="flex items-center mt-1">
              <Switch
                checked={seePipeInSource}
                onChange={setSeePipeInSource}
                className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span
                  aria-hidden="true"
                  className="pointer-events-none absolute bg-white w-full h-full rounded-md"
                />
                <span
                  aria-hidden="true"
                  className={`${
                    seePipeInSource ? "bg-blue-600" : "bg-gray-200"
                  } pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`}
                />
                <span
                  aria-hidden="true"
                  className={`${
                    seePipeInSource ? "translate-x-5" : "translate-x-0"
                  } ointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-xs text-gray-500">
                  {seePipeInSource ? "Source > Pipe" : "Pipe > Source"}
                </span>
              </Switch.Label>
            </Switch.Group>
          </div>
          <div className="mt-4">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                const params = buildParams({
                  acquisitions,
                  seePipeInSource,
                  rangeOption,
                  acquisitionsFrom,
                  acquisitionsTo,
                });
                setIsLoading(true);
                setStatsSources(null);
                http
                  .get("/stats_sources_pipes/", {
                    params,
                    paramsSerializer: (params) =>
                      qs.stringify(params, {
                        skipNulls: true,
                        arrayFormat: "repeat",
                      }),
                  })
                  .then((response) => {
                    setStatsSources(response.data);
                  })
                  .finally(() => setIsLoading(false));
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  className="animate-spin -ml-1 mr-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-65"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <ChartBarSquareIcon className="-ml-1 mr-2 h-5 w-5" />
              )}
              Voir les stats
            </button>
          </div>
        </div>

        {statsSources !== null && (
          <div className="mt-5 bg-white">
            <table className="min-w-full">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Acquisition
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center"
                  >
                    Total prospects
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center"
                  >
                    Nouveaux prospects
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center"
                  >
                    Prospects connus
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center"
                  >
                    Prospects désinscrits
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {statsSources &&
                  statsSources[key]
                    .filter((source) => source.nb_prospects !== 0)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((source) => (
                      <Fragment key={source.name}>
                        <tr className="border-t border-gray-200">
                          <td className="bg-gray-50 whitespace-nowrap font-semibold py-4 pl-4 pr-3 text-sm sm:pl-6">
                            {source.name}
                          </td>
                          <td className="bg-gray-50 whitespace-nowrap font-semibold px-3 py-4 text-sm text-center">
                            {source.nb_prospects}
                          </td>
                          <td className="bg-gray-50 whitespace-nowrap font-semibold px-3 py-4 text-sm text-center">
                            {source.nb_new}
                          </td>
                          <td className="bg-gray-50 whitespace-nowrap font-semibold px-3 py-4 text-sm text-center">
                            {source.nb_known}
                          </td>
                          <td className="bg-gray-50 whitespace-nowrap font-semibold px-3 py-4 text-sm text-center">
                            {source.nb_unsubscribed}
                          </td>
                          <td className="bg-gray-50 whitespace-nowrap px-3 py-4 text-sm text-center">
                            <button
                              type="button"
                              className="text-blue-500"
                              onClick={(e) => {
                                e.preventDefault();
                                setProspects(
                                  Object.values(source.prospects_ids)
                                    .filter(
                                      (prospect_id) =>
                                        prospect_id in statsSources?.prospects
                                    )
                                    .map(
                                      (prospect_id) =>
                                        statsSources.prospects[prospect_id]
                                    )
                                );
                                setSeeProspectDetails(true);
                              }}
                            >
                              voir les prospects
                            </button>
                          </td>
                        </tr>
                        {Object.values(source.children).map((pipe, id) => (
                          <tr
                            key={pipe.name}
                            className={
                              id === 0
                                ? "border-t border-gray-300"
                                : "border-t border-gray-200"
                            }
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                              {pipe.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                              {pipe.nb_prospects}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                              {pipe.nb_new}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                              {pipe.nb_known}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                              {pipe.nb_unsubscribed}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                              <button
                                type="button"
                                className="text-blue-500"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setProspects(
                                    Object.values(pipe.prospects_ids)
                                      .filter(
                                        (prospect_id) =>
                                          prospect_id in statsSources?.prospects
                                      )
                                      .map(
                                        (prospect_id) =>
                                          statsSources.prospects[prospect_id]
                                      )
                                  );
                                  setSeeProspectDetails(true);
                                }}
                              >
                                voir les prospects
                              </button>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Page>
  );
}
