import { useEffect, useState } from "react";
import * as React from "react";
import * as localStorage from "../services/localStorage";
import * as authActions from "./authActions";
import { CurrentUser } from "../types";
import NotAuthenticatedLoadingPage from "../pages/NotAuthenticatedLoadingPage";
import gravatarUrl from "gravatar-url";

interface AuthContextType {
  currentUser: CurrentUser | null;
  signIn: (username: string, password: string) => Promise<void>;
  logout: () => void;
}
export const AuthContext = React.createContext<AuthContextType>(null!);

type AuthProviderProps = {
  children: React.ReactElement;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);

  useEffect(() => {
    const token = localStorage.getToken();
    if (token) {
      authActions
        .getCurrentUser()
        .then((u) => {
          return setCurrentUser({
            ...u,
            imageURL: gravatarUrl(u.email, {
              size: 256,
            }),
          });
        })
        .catch((e) => {
          if (e?.response?.status) {
            console.error(e.response);
          } else {
            console.error("Le serveur est déconnecté.");
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <NotAuthenticatedLoadingPage />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signIn: (username, password) => {
          return authActions
            .getTokens(username, password)
            .then((data) => {
              localStorage.setToken(data.access);
              return data;
            })
            .then(authActions.getCurrentUser)
            .then((u) => {
              return setCurrentUser({
                ...u,
                imageURL: gravatarUrl(u.email, {
                  size: 256,
                }),
              });
            });
        },
        logout: () => {
          setCurrentUser(null);
          localStorage.removeToken();
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}
