import React from "react";

export default function Page({
  title,
  children,
  ...props
}: {
  title: string;
  children: React.ReactNode;
  [k: string]: any;
}) {
  return (
    <div {...props}>
      <header className="bg-white shadow-sm">
        <div className="max-w-screen-2xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <h2 className="text-lg font-medium text-gray-900">{title}</h2>
        </div>
      </header>
      <main>
        <div className="max-w-screen-2xl mx-auto py-6 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
}
