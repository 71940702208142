import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select, { InputActionMeta, MultiValue } from "react-select";
import { DateTime } from "luxon";

type MyOption = { value: string; label: string };

function stringArrayToOptions(arr: string[]): MyOption[] {
  return arr.map((d) => ({
    value: d,
    label: d,
  }));
}

function stringOptionsToArrayOfString(
  arr: MyOption[] | MultiValue<MyOption>
): string[] {
  return arr.map((d) => d.value);
}

export default function SelectWithRangeAndSelectAll({
  onLoad,
  selectedElements,
  setSelectedElements,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  label,
}: {
  label: string;
  labelAll?: string;
  onLoad: () => Promise<string[]>;
  selectedElements: string[];
  setSelectedElements: (elements: string[]) => void;
  startDate: Date | null;
  setStartDate: (d: Date | null) => void;
  endDate: Date | null;
  setEndDate: (d: Date | null) => void;
}) {
  const rangeOptions = [
    { value: "previousWeek", label: "Semaine dernière" },
    { value: "previousMonth", label: "Mois dernier" },
    { value: "previousQuarter", label: "Trimestre dernier" },
    { value: "lastMonth", label: "Dernier mois" },
    { value: "lastYear", label: "Année dernière" },
    { value: "yesterday", label: "Hier" },
    { value: "today", label: "Aujourd'hui" },
    { value: "currentWeek", label: "Semaine en cours" },
    { value: "currentMonth", label: "Mois en cours" },
    { value: "currentQuarter", label: "Trimestre courant" },
    { value: "currentYear", label: "Cumul annuel en cours" },
    { value: "last30Days", label: "30 derniers jours" },
    { value: "last90Days", label: "90 derniers jours" },
    { value: "last365Days", label: "365 derniers jours" },
    {
      value: "custom",
      label: "Définir la période manuellement",
    },
  ];
  const [inputValue, setInputValue] = useState("");
  const [elements, setElements] = useState<MyOption[]>([]);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>();
  const [rangeChoice, setRangeChoice] = useState<{
    value: string;
    label: string;
  } | null>(startDate === null || endDate === null ? null : rangeOptions[0]);
  const [seeDatePicker, setSeeDatePicker] = useState(
    startDate !== null && endDate !== null
  );

  useEffect(() => {
    onLoad().then((e) => {
      setElements(stringArrayToOptions(e));
    });
  }, []);

  const options = [{ label: "Tous", value: "all" }, ...elements];

  return (
    <div>
      <div>
        <label
          htmlFor="elements"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <Select
          isMulti
          isClearable
          isSearchable
          closeMenuOnSelect={false}
          inputValue={inputValue}
          onInputChange={(inputValue: string, { action }: InputActionMeta) => {
            if (
              action === "menu-close" ||
              action === "input-blur" ||
              action === "set-value"
            ) {
              return;
            } else {
              setInputValue(inputValue);
            }
          }}
          noOptionsMessage={() => null}
          name={label.toLowerCase()}
          value={stringArrayToOptions(selectedElements)}
          options={options}
          menuIsOpen={menuIsOpen}
          onChange={(selected) => {
            selected.length && selected.find((option) => option.value === "all")
              ? setSelectedElements(stringOptionsToArrayOfString(elements))
              : setSelectedElements(stringOptionsToArrayOfString(selected));
          }}
        />
      </div>
      <div className="mt-3">
        <label
          htmlFor="elements"
          className="block text-sm font-medium text-gray-700"
        >
          Période
        </label>
        <Select
          value={rangeChoice}
          options={rangeOptions}
          isClearable={true}
          onChange={(choice) => {
            setRangeChoice(choice);
            setStartDate(null);
            setEndDate(null);
            setSeeDatePicker(false);
            if (!choice) return;
            if (choice.value === "custom") {
              setSeeDatePicker(true);
            }
            const today = DateTime.now();
            const yesterday = today.minus({
              day: 1,
            });
            if (choice.value === "today") {
              setStartDate(today.startOf("day").toJSDate());
              setEndDate(today.endOf("day").toJSDate());
            }
            if (choice.value === "currentWeek") {
              setStartDate(today.startOf("week").toJSDate());
              setEndDate(today.endOf("week").toJSDate());
            }
            if (choice.value === "currentMonth") {
              setStartDate(today.startOf("month").toJSDate());
              setEndDate(today.endOf("month").toJSDate());
            }
            if (choice.value === "currentQuarter") {
              setStartDate(today.startOf("quarter").toJSDate());
              setEndDate(today.endOf("quarter").toJSDate());
            }
            if (choice.value === "currentYear") {
              setStartDate(today.startOf("year").toJSDate());
              setEndDate(today.endOf("year").toJSDate());
            }
            if (choice.value === "yesterday") {
              setStartDate(yesterday.startOf("day").toJSDate());
              setEndDate(yesterday.endOf("day").toJSDate());
            }
            if (choice.value === "previousWeek") {
              const lastWeek = today.minus({
                week: 1,
              });
              setStartDate(lastWeek.startOf("week").toJSDate());
              setEndDate(lastWeek.endOf("week").toJSDate());
            }
            if (choice.value === "previousMonth") {
              const lastMonth = today.minus({
                month: 1,
              });
              setStartDate(lastMonth.startOf("month").toJSDate());
              setEndDate(lastMonth.endOf("month").toJSDate());
            }
            if (choice.value === "previousQuarter") {
              const lastQuarter = today.minus({
                quarter: 1,
              });
              setStartDate(lastQuarter.startOf("quarter").toJSDate());
              setEndDate(lastQuarter.endOf("quarter").toJSDate());
            }
            if (choice.value === "lastMonth") {
              const lastMonth = today.minus({
                month: 1,
              });
              setStartDate(lastMonth.startOf("month").toJSDate());
              setEndDate(lastMonth.endOf("month").toJSDate());
            }
            if (choice.value === "lastYear") {
              const lastYear = today.minus({
                year: 1,
              });
              setStartDate(lastYear.startOf("year").toJSDate());
              setEndDate(lastYear.endOf("year").toJSDate());
            }
            if (choice.value === "last30Days") {
              const last30Days = today.minus({
                days: 30,
              });
              setStartDate(last30Days.startOf("day").toJSDate());
              setEndDate(yesterday.endOf("day").toJSDate());
            }
            if (choice.value === "last90Days") {
              const last90Days = today.minus({
                days: 90,
              });
              setStartDate(last90Days.startOf("day").toJSDate());
              setEndDate(yesterday.endOf("day").toJSDate());
            }
            if (choice.value === "last365Days") {
              const last365Days = today.minus({
                days: 365,
              });
              setStartDate(last365Days.startOf("day").toJSDate());
              setEndDate(yesterday.endOf("day").toJSDate());
            }
          }}
        />
      </div>
      {seeDatePicker && (
        <div className="grid gap-x-2 grid-cols-2">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            locale="fr"
            className="form-input block w-full sm:text-sm sm:leading-5 border border-gray-300 rounded-md mt-1"
            placeholderText="Du"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            locale="fr"
            highlightDates={[new Date()]}
            className="form-input block w-full sm:text-sm sm:leading-5 border border-gray-300 rounded-md mt-1"
            placeholderText="A"
          />
        </div>
      )}
    </div>
  );
}
