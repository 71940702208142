import { useEffect, useState } from "react";
import http from "./services/http";
import Page from "./layout/Page";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import {
  CheckIcon,
  XCircleIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
  UserIcon,
  QuestionMarkCircleIcon,
  MagnifyingGlassIcon,
  BellSlashIcon,
} from "@heroicons/react/24/solid";
import { ProspectStatus } from "./types";

interface Sale {
  date: string;
  name: string;
  status: string;
  created_at: string;
  amount: string;
}
interface Unsubscription {
  date: string;
  created_at: string;
}
interface ProspectInformation {
  email: string;
  status: ProspectStatus;
  acquisitions: { date: string; name: string; created_at: string }[];
  sequences: { date: string; name: string; created_at: string }[];
  sales: Sale[];
  unsubscriptions: Unsubscription[];
}

interface getProspectInfoResponse {
  data: ProspectInformation;
}

function getProspectInfo(email: string): Promise<getProspectInfoResponse> {
  return http.get(`/prospects/?email=${email}`);
}

export function ISODateToHuman(
  date: string,
  formatOpts: Intl.DateTimeFormatOptions = DateTime.DATE_MED
) {
  const datetime = DateTime.fromISO(date);
  if (datetime.isValid) {
    return datetime.toLocaleString(formatOpts);
  }
  return "";
}

export function sortByDateNewerFirst(
  s1: Required<{ date: string }>,
  s2: Required<{ date: string }>
) {
  return (
    DateTime.fromISO(s2.date).toMillis() - DateTime.fromISO(s1.date).toMillis()
  );
}

function getEventIconInfo(sale: Sale) {
  switch (sale.status) {
    case "subscription-payment":
    case "sales":
    case "order.success":
      return {
        icon: CheckIcon,
        iconBackground: "bg-green-500",
        name: `a acheté le produit ${sale.name} pour un montant de ${sale.amount}€`,
      };
    case "transaction-pending":
    case "order.subscription_paused":
      return {
        icon: InformationCircleIcon,
        iconBackground: "bg-orange-500",
        name: `paiement en attente (${sale.amount}€)`,
      };

    case "cart-abandoned":
    case "cart.abandoned":
      return {
        icon: InformationCircleIcon,
        iconBackground: "bg-orange-500",
        name: "abandon du panier",
      };
    case "subscription-payment-failed":
      return {
        icon: ExclamationCircleIcon,
        iconBackground: "bg-red-500",
        name: `echec du paiement (${sale.amount}€)`,
      };
    case "subscription-updated":
      return {
        icon: InformationCircleIcon,
        iconBackground: "bg-blue-500",
        name: "mise à jour des informations de paiement",
      };
    case "subscription-cancelled":
      return {
        icon: XCircleIcon,
        iconBackground: "bg-red-500",
        name: "paiement annulé",
      };
    case "refund":
    case "order.refund":
      return {
        icon: XCircleIcon,
        iconBackground: "bg-red-500",
        name: `a demandé le remboursement du produit ${sale.name} (${sale.amount}€)`,
      };
    default:
      return {
        icon: QuestionMarkCircleIcon,
        iconBackground: "bg-gray-400",
        name: `${sale.status}`,
      };
  }
}

function prospectInformationToFeed(info: ProspectInformation) {
  const feed = [];

  for (let i = 0; i < info.acquisitions.length; i++) {
    const acquisition = info.acquisitions[i];
    feed.push({
      name: `acquisition via ${acquisition.name}`,
      datetime: acquisition.created_at,
      date: acquisition.date,
      icon: UserIcon,
      iconBackground: "bg-blue-500",
    });
  }

  for (let i = 0; i < info.sequences.length; i++) {
    const sequence = info.sequences[i];
    feed.push({
      name: `est passé dans la séquence ${sequence.name}`,
      datetime: sequence.created_at,
      date: sequence.date,
      icon: InformationCircleIcon,
      iconBackground: "bg-blue-500",
    });
  }

  for (let i = 0; i < info.sales.length; i++) {
    const sale = info.sales[i];
    feed.push({
      datetime: sale.created_at,
      date: sale.date,
      ...getEventIconInfo(sale),
    });
  }

  for (let i = 0; i < info.unsubscriptions.length; i++) {
    const unbsubscription = info.unsubscriptions[i];
    feed.push({
      datetime: unbsubscription.created_at,
      date: unbsubscription.date,
      name: "désinscription",
      icon: BellSlashIcon,
      iconBackground: "bg-orange-500",
    });
  }

  return feed.sort(sortByDateNewerFirst);
}

function Timeline({ prospectInfo }: { prospectInfo: ProspectInformation }) {
  const timeline = prospectInformationToFeed(prospectInfo);
  return (
    <div>
      {timeline.length === 0 && (
        <div className="pb-12">
          <span>
            Il n'y a aucun évènement associé à l'email {prospectInfo.email}
          </span>
        </div>
      )}
      <ul className="-mb-8">
        {timeline.map((status, i) => (
          <li key={i}>
            <div className="relative pb-8">
              {i !== timeline.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={`${status.iconBackground} h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`}
                  >
                    <status.icon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">{status.name}</p>
                  </div>
                  <div className="text-right text-sm whitespace-nowrap text-gray-500">
                    <time dateTime={status.datetime} title={status.datetime}>
                      {ISODateToHuman(status.date)}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default function ProspectPage() {
  const [searchEmail, setSearchEmail] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [prospectInfo, setProspectInfo] = useState<ProspectInformation | null>(
    null
  );

  useEffect(() => {
    const email = searchParams.get("email");
    if (email) {
      setSearchEmail(email);
      getProspectInfo(email).then((response) => setProspectInfo(response.data));
    }
  }, []);

  return (
    <Page title="Prospect">
      <div className="mx-auto p-4 sm:p-6 lg:p-8 bg-white">
        <form
          className="space-y-5"
          onSubmit={(e) => {
            e.preventDefault();
            getProspectInfo(searchEmail)
              .then((response) => {
                setProspectInfo(response.data);
                navigate(`/prospect?email=${searchEmail}`);
              })
              .catch(() => {
                setProspectInfo(null);
                alert(`${searchEmail} n'est pas un email invalide`);
              });
          }}
        >
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={searchEmail}
                  placeholder="contact@roihunter.fr"
                  onChange={(e) => setSearchEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={searchEmail === ""}
            >
              <MagnifyingGlassIcon className="-ml-1 mr-2 h-5 w-5" />
              Rechercher
            </button>
          </div>
        </form>
        {prospectInfo === null ? null : (
          <div className="py-12 bg-white">
            <Timeline prospectInfo={prospectInfo} />
          </div>
        )}
      </div>
    </Page>
  );
}
