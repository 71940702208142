import { Fragment, useEffect, useState } from "react";
import http from "./services/http";
import {
  ChartBarSquareIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Page from "./layout/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { ISODateToHuman } from "./ProspectPage";
import { parseDate, toIsoDateOrNull } from "./date";
import qs from "qs";
import SelectWithRangeAndSelectAll from "./components/SelectWithRangeAndSelectAll";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

interface IStatsAcquisitions {
  nb_acquisitions: number;
  acquisitions: {
    name: string;
    nb_prospects: number;
    nb_new_prospects: number;
    prospects: {
      email: string;
      date_acquisition: string;
      new: boolean;
    }[];
  }[];
}

interface IStatsProspects {
  nb_prospects: number;
  nb_new_prospects: number;
  prospects: {
    email: string;
    new: boolean;
    acquisitions: {
      name: string;
      date_acquisition: string;
    }[];
  }[];
}

function buildParams({
  acquisitions,
  acquisitionsFrom,
  acquisitionsTo,
}: {
  acquisitions: string[];
  acquisitionsFrom: Date | null;
  acquisitionsTo: Date | null;
}) {
  return {
    acquisitions,
    acquisitions_from: toIsoDateOrNull(acquisitionsFrom),
    acquisitions_to: toIsoDateOrNull(acquisitionsTo),
  };
}

export function StatsProspects({
  statsProspects,
}: {
  statsProspects: IStatsProspects;
}) {
  const [seeDetails, setSeeDetails] = useState(false);

  return (
    <div>
      <Transition.Root show={seeDetails} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setSeeDetails}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Détails
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                              onClick={() => setSeeDetails(false)}
                            >
                              <span className="sr-only">Fermer</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {seeDetails && (
                          <div>
                            <table className="min-w-full bg-white">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Email
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Acquisition
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Date d'acquisition
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {statsProspects.prospects.map((prospect) =>
                                  prospect.acquisitions.map(
                                    (acquisition, i) => (
                                      <tr>
                                        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                          {i === 0 && (
                                            <Link
                                              to={`/prospect?email=${prospect.email}`}
                                              className="text-blue-500 hover:underline flex items-center gap-1"
                                            >
                                              {prospect.email}
                                              {prospect.new && (
                                                <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 ml-2">
                                                  new
                                                </span>
                                              )}
                                            </Link>
                                          )}
                                        </td>
                                        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                          {acquisition.name}
                                        </td>
                                        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                          {ISODateToHuman(
                                            acquisition.date_acquisition
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <table className="min-w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Nb de prospects
            </th>
            <th
              scope="col"
              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Nb de nouveaux prospects
            </th>
            <th
              scope="col"
              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              {statsProspects.nb_prospects}
            </td>
            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
              {statsProspects.nb_new_prospects}
            </td>
            <td>
              {statsProspects.nb_prospects > 0 && (
                <button
                  type="button"
                  className="text-blue-500 text-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    setSeeDetails(!seeDetails);
                  }}
                >
                  {seeDetails ? "cacher le detail" : "voir le detail"}
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export function StatsAcquisitions({
  statsAcquisitions,
  onAcquisitionClick,
}: {
  statsAcquisitions: IStatsAcquisitions;
  onAcquisitionClick: (acquisitionName: string) => void;
}) {
  const [seeDetails, setSeeDetails] = useState(false);
  const [statDetails, setStatDetails] = useState<
    IStatsAcquisitions["acquisitions"][0]["prospects"]
  >([]);

  const sources = statsAcquisitions.acquisitions.filter((a) =>
    a.name.toLowerCase().startsWith("source")
  );
  const pipes = statsAcquisitions.acquisitions.filter((a) =>
    a.name.toLowerCase().startsWith("pipe")
  );
  const others = statsAcquisitions.acquisitions.filter(
    (a) =>
      !a.name.toLowerCase().startsWith("source") &&
      !a.name.toLowerCase().startsWith("pipe")
  );

  const emails_in_sources: { [k: string]: number } = {};
  const emails_not_in_sources: { [email: string]: number } = {};
  for (let i = 0; i < sources.length; i++) {
    const source = sources[i];
    for (let j = 0; j < source.prospects.length; j++) {
      const prospect = source.prospects[j];
      if (!(prospect.email in emails_in_sources)) {
        emails_in_sources[prospect.email] = 1;
      }
    }
  }
  for (let i = 0; i < pipes.length; i++) {
    const pipe = pipes[i];
    for (let j = 0; j < pipe.prospects.length; j++) {
      const prospect = pipe.prospects[j];
      if (!(prospect.email in emails_in_sources)) {
        emails_not_in_sources[prospect.email] = 1;
      }
    }
  }

  return (
    <div>
      <Transition.Root show={seeDetails} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setSeeDetails}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Détails
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                              onClick={() => setSeeDetails(false)}
                            >
                              <span className="sr-only">Fermer</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {statDetails.length !== 0 && (
                          <div className="mt-5">
                            <table className="min-w-full bg-white">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Email
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Date d'acquisition
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {statDetails.map((stat) => (
                                  <tr>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {
                                        <Link
                                          to={`/prospect?email=${stat.email}`}
                                          className="text-blue-500 hover:underline flex items-center gap-1"
                                        >
                                          {stat.email}
                                          {stat.new && (
                                            <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 ml-2">
                                              new
                                            </span>
                                          )}
                                        </Link>
                                      }
                                    </td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {ISODateToHuman(stat.date_acquisition)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <table className="min-w-full bg-white">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Acquisition
            </th>
            <th
              scope="col"
              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Nb de prospects
            </th>
            <th
              scope="col"
              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Nb de nouveaux prospects
            </th>
            <th
              scope="col"
              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {sources.map((acquisition) => (
            <tr>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.name}
                <button
                  type="button"
                  className="inline-flex items-center rounded-full border border-transparent bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ml-2"
                  onClick={() => {
                    onAcquisitionClick(acquisition.name);
                  }}
                >
                  <PlusIcon className="h-3 w-3" aria-hidden="true" />
                </button>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.nb_prospects}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.nb_new_prospects}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.prospects.length > 0 && (
                  <button
                    type="button"
                    className="text-blue-500"
                    onClick={(e) => {
                      e.preventDefault();
                      setStatDetails(acquisition.prospects);
                      setSeeDetails(true);
                    }}
                  >
                    voir les prospects
                  </button>
                )}
              </td>
            </tr>
          ))}
          {sources.length > 0 && (
            <tr className="border-y border-blue-500">
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                Total sources
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {sources.reduce((acc, source) => {
                  acc += source.nb_prospects;
                  return acc;
                }, 0)}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {sources.reduce((acc, source) => {
                  acc += source.nb_new_prospects;
                  return acc;
                }, 0)}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500"></td>
            </tr>
          )}
          {pipes.map((acquisition) => (
            <tr>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.name}
                <button
                  type="button"
                  className="inline-flex items-center rounded-full border border-transparent bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ml-2"
                  onClick={() => {
                    onAcquisitionClick(acquisition.name);
                  }}
                >
                  <PlusIcon className="h-3 w-3" aria-hidden="true" />
                </button>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.nb_prospects}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.nb_new_prospects}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.prospects.length > 0 && (
                  <button
                    type="button"
                    className="text-blue-500"
                    onClick={(e) => {
                      e.preventDefault();
                      setStatDetails(acquisition.prospects);
                      setSeeDetails(true);
                    }}
                  >
                    voir les prospects
                  </button>
                )}
              </td>
            </tr>
          ))}
          {pipes.length > 0 && (
            <tr className="border-y border-blue-500">
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                Total pipes
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {pipes.reduce((acc, source) => {
                  acc += source.nb_prospects;
                  return acc;
                }, 0)}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {pipes.reduce((acc, source) => {
                  acc += source.nb_new_prospects;
                  return acc;
                }, 0)}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500"></td>
            </tr>
          )}
          {others.map((acquisition) => (
            <tr>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.name}
                <button
                  type="button"
                  className="inline-flex items-center rounded-full border border-transparent bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ml-2"
                  onClick={() => {
                    onAcquisitionClick(acquisition.name);
                  }}
                >
                  <PlusIcon className="h-3 w-3" aria-hidden="true" />
                </button>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.nb_prospects}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.nb_new_prospects}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {acquisition.prospects.length > 0 && (
                  <button
                    type="button"
                    className="text-blue-500"
                    onClick={(e) => {
                      e.preventDefault();
                      setStatDetails(acquisition.prospects);
                      setSeeDetails(true);
                    }}
                  >
                    voir les prospects
                  </button>
                )}
              </td>
            </tr>
          ))}
          {others.length > 0 && (
            <tr>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500"></td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {others.reduce((acc, source) => {
                  acc += source.nb_prospects;
                  return acc;
                }, 0)}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                {others.reduce((acc, source) => {
                  acc += source.nb_new_prospects;
                  return acc;
                }, 0)}
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500"></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default function PageAcquisitions() {
  const navigate = useNavigate();
  const location = useLocation();
  const filters = {
    acquisitions: [],
    acquisitions_from: null,
    acquisitions_to: null,
    ...qs.parse(location.search, { ignoreQueryPrefix: true }),
  };
  const [acquisitions, setAcquisitions] = useState<string[]>(
    filters.acquisitions
  );
  const [acquisitionsFrom, setAcquisitionsFrom] = useState<Date | null>(
    parseDate(filters.acquisitions_from)
  );
  const [acquisitionsTo, setAcquisitionsTo] = useState<Date | null>(
    parseDate(filters.acquisitions_to)
  );
  const [statsProspects, setStatsProspects] = useState<IStatsProspects | null>(
    null
  );
  const [statsAcquisitions, setStatsAcquisitions] =
    useState<IStatsAcquisitions | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = buildParams({
      acquisitions,
      acquisitionsFrom,
      acquisitionsTo,
    });
    navigate(
      `/acquisitions?${qs.stringify(params, {
        arrayFormat: "brackets",
        skipNulls: true,
        encode: false,
      })}`
    );
  }, [navigate, acquisitions, acquisitionsFrom, acquisitionsTo]);

  return (
    <Page title="ROI Hunter">
      <div className="px-4 py-5 sm:p-0">
        <div className="p-5 grid gap-x-4 grid-cols-3 bg-white">
          <div>
            <SelectWithRangeAndSelectAll
              label="Acquisitions"
              onLoad={() =>
                http
                  .get("/acquisitions/")
                  .then((response) => response.data as string[])
              }
              selectedElements={acquisitions}
              setSelectedElements={setAcquisitions}
              startDate={acquisitionsFrom}
              setStartDate={setAcquisitionsFrom}
              endDate={acquisitionsTo}
              setEndDate={setAcquisitionsTo}
            />
          </div>
          <div />
          <div />
          <div className="mt-4">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                const params = buildParams({
                  acquisitions,
                  acquisitionsFrom,
                  acquisitionsTo,
                });
                setIsLoading(true);
                setStatsAcquisitions(null);
                setStatsProspects(null);
                if (acquisitions.length === 0) {
                  http
                    .get("/stats_acquisitions/", {
                      params,
                      paramsSerializer: (params) =>
                        qs.stringify(params, {
                          skipNulls: true,
                          arrayFormat: "repeat",
                        }),
                    })
                    .then((response) => {
                      setStatsAcquisitions(response.data);
                    })
                    .finally(() => setIsLoading(false));
                } else {
                  http
                    .get("/stats_prospects/", {
                      params,
                      paramsSerializer: (params) =>
                        qs.stringify(params, {
                          skipNulls: true,
                          arrayFormat: "repeat",
                        }),
                    })
                    .then((response) => {
                      setStatsProspects(response.data);
                    })
                    .finally(() => setIsLoading(false));
                }
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  className="animate-spin -ml-1 mr-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-65"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <ChartBarSquareIcon className="-ml-1 mr-2 h-5 w-5" />
              )}
              Voir les stats
            </button>
          </div>
        </div>

        <div className="mt-5 bg-white">
          {statsAcquisitions !== null && (
            <StatsAcquisitions
              onAcquisitionClick={(acquisitionName) => {
                setAcquisitions((prevState) => [...prevState, acquisitionName]);
              }}
              statsAcquisitions={statsAcquisitions}
            />
          )}
          {statsProspects !== null && (
            <StatsProspects statsProspects={statsProspects} />
          )}
        </div>
      </div>
    </Page>
  );
}
