import http from "../services/http";
import { Tokens } from "../types";

export function getTokens(username: string, password: string): Promise<Tokens> {
  return http
    .post(`/auth/jwt/create/`, { username, password })
    .then((response) => response.data);
}

export function getCurrentUser(): Promise<{
  id: string;
  email: string;
  username: string;
}> {
  return http.get("/auth/users/me/").then((response) => response.data);
}
