import axios from "axios";
import { getToken, removeToken } from "./localStorage";

const baseURL =
  process.env.REACT_APP_BACKEND_HOST ||
  "https://server-roihunter-8ferw.ondigitalocean.app";

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use((config) => {
  const token = getToken();
  if (config && config.headers && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const token = getToken();
    if (token === null || error?.response?.status !== 401) {
      return Promise.reject(error);
    }
    return removeToken();
  }
);

export default axios;
