import { Routes, Route } from "react-router-dom";
import PageVentes from "./PageVentes";
import SignInPage from "./auth/SignInPage";
import RegisterPage from "./auth/RegisterPage";
import ResetPasswordPage from "./auth/ResetPasswordPage";
import PrivacyPolicyPage from "./auth/PrivacyPolicyPage";
import UserAgreementPage from "./auth/UserAgreementPage";
import ResetPasswordConfirmPage from "./auth/ResetPasswordConfirmPage";
import NotAuthenticatedLayout from "./layout/NotAuthenticatedLayout";
import AuthenticatedLayout from "./layout/AuthenticatedLayout";
import CohortePage from "./CohortePage";
import ProspectPage from "./ProspectPage";
import Page404 from "./pages/Page404";
import PageAcquisitions from "./PageAcquisitions";
import PageAcquisitions2 from "./PageAcquisitions2";

export default function App() {
  return (
    <Routes>
      <Route path="/">
        <Route element={<AuthenticatedLayout />}>
          <Route index element={<PageVentes />} />
          <Route path="acquisitions" element={<PageAcquisitions />} />
          <Route path="stats_sources_pipes" element={<PageAcquisitions2 />} />
          <Route path="cohorte" element={<CohortePage />} />
          <Route path="prospect" element={<ProspectPage />} />
        </Route>
        <Route element={<NotAuthenticatedLayout />}>
          <Route path="sign-in" element={<SignInPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="reset-your-password" element={<ResetPasswordPage />} />
          <Route
            path="reset-password-confirm"
            element={<ResetPasswordConfirmPage />}
          />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="user-agreement" element={<UserAgreementPage />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}
