import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../auth/authContext";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChartBarSquareIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function AuthenticatedLayout({ ...props }) {
  const { currentUser, logout } = useAuth();
  const location = useLocation();

  if (currentUser === null) {
    // Redirect them to the /login page, but save the current user location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return (
    <div {...props}>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-blue-800">
          {({ open }) => (
            <>
              <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <ChartBarSquareIcon className="h-8 w-8 text-white" />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        <Link
                          to="/stats_sources_pipes"
                          className={
                            location.pathname === "/stats_sources_pipes"
                              ? "bg-blue-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                              : "text-gray-300 hover:bg-blue-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          }
                        >
                          Acquisitions
                        </Link>
                        <Link
                          to="/"
                          className={
                            location.pathname === "/"
                              ? "bg-blue-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                              : "text-gray-300 hover:bg-blue-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          }
                        >
                          Ventes
                        </Link>
                        <Link
                          to="/prospect"
                          className={
                            location.pathname === "/prospect"
                              ? "bg-blue-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                              : "text-gray-300 hover:bg-blue-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          }
                        >
                          Prospect
                        </Link>
                        <Link
                          to="/cohorte"
                          className={
                            location.pathname === "/cohorte"
                              ? "bg-blue-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                              : "text-gray-300 hover:bg-blue-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          }
                        >
                          Cohorte
                        </Link>
                        <a
                          href="https://api.roihunter.fr/admin/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-300 hover:bg-blue-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Admin
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs bg-blue-800 rounded-full flex items-center text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white">
                            <span className="sr-only">Ouvrir menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={currentUser.imageURL}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="button"
                                  className={
                                    active
                                      ? "w-full block bg-gray-100 px-4 py-2 text-sm text-gray-700"
                                      : "w-full block px-4 py-2 text-sm text-gray-700"
                                  }
                                  onClick={logout}
                                >
                                  Déconnexion
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    <Disclosure.Button className="bg-blue-800 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white">
                      <span className="sr-only">Ouvrir menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  <Link
                    to="/acquisitions"
                    className="bg-blue-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Acquisitions
                  </Link>
                  <Link
                    to="/"
                    className="bg-blue-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Ventes
                  </Link>
                  <Link
                    to="/prospect"
                    className="bg-blue-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Prospect
                  </Link>
                  <Link
                    to="/cohorte"
                    className="bg-blue-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Cohorte
                  </Link>
                  <a
                    href="https://api.roihunter.fr/admin/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Admin
                  </a>
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={currentUser.imageURL}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">
                        {currentUser.username}
                      </div>
                      <div className="text-sm font-medium text-gray-400">
                        {currentUser.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <button
                      type="button"
                      className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white"
                      onClick={logout}
                    >
                      Déconnexion
                    </button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Outlet />
      </div>
    </div>
  );
}
