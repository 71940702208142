import { useEffect, useState } from "react";
import Select, { InputActionMeta, MultiValue } from "react-select";

type MyOption = { value: string; label: string };

function stringArrayToOptions(arr: string[]): MyOption[] {
  return arr.map((d) => ({
    value: d,
    label: d,
  }));
}

function stringOptionsToArrayOfString(
  arr: MyOption[] | MultiValue<MyOption>
): string[] {
  return arr.map((d) => d.value);
}

export default function SelectAll({
  id,
  onLoad,
  selectedElements,
  setSelectedElements,
}: {
  id: string;
  onLoad: () => Promise<string[]>;
  selectedElements: string[];
  setSelectedElements: (elements: string[]) => void;
}) {
  const [inputValue, setInputValue] = useState("");
  const [elements, setElements] = useState<MyOption[]>([]);

  useEffect(() => {
    onLoad().then((e) => {
      setElements(stringArrayToOptions(e));
    });
  }, [onLoad]);

  const options = [{ label: "Tous", value: "all" }, ...elements];

  return (
    <Select
      isMulti
      isClearable
      isSearchable
      closeMenuOnSelect={false}
      inputValue={inputValue}
      onInputChange={(inputValue: string, { action }: InputActionMeta) => {
        if (
          action === "menu-close" ||
          action === "input-blur" ||
          action === "set-value"
        ) {
          return;
        } else {
          setInputValue(inputValue);
        }
      }}
      noOptionsMessage={() => null}
      id={id}
      name={id}
      value={stringArrayToOptions(selectedElements)}
      options={options}
      onChange={(selected, actionMeta) => {
        actionMeta.option &&
        actionMeta.action === "select-option" &&
        actionMeta.option.value === "all"
          ? setSelectedElements(
              stringOptionsToArrayOfString([{ label: "Tous", value: "all" }])
            )
          : setSelectedElements(
              stringOptionsToArrayOfString(
                selected.filter((option) => option.value !== "all")
              )
            );
      }}
    />
  );
}
