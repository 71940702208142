import { useEffect, useState } from "react";
import http from "./services/http";
import Page from "./layout/Page";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { UsersIcon } from "@heroicons/react/24/outline";
import { Switch } from "@headlessui/react";
import Select from "react-select";
import fuzzysort from "fuzzysort";

interface CohorteMonth {
  nb: number;
  total: number;
  per_prospect: number;
  nb_cumulated: number;
  total_cumulated: number;
  per_prospect_cumulated: number;
}

interface CohorteRow {
  month: string;
  acquisitions: number;
  sales: CohorteMonth[];
}

function toRange(from: Date | null, to: Date | null) {
  return {
    from: from === null ? null : DateTime.fromJSDate(from).toISODate(),
    to: to === null ? null : DateTime.fromJSDate(to).toISODate(),
  };
}

type DisplaySale = "nb" | "total" | "per_prospect";

function CohorteValue({
  value,
  cumulated,
  displaySales,
}: {
  value: CohorteMonth;
  cumulated: boolean;
  displaySales: DisplaySale;
}) {
  if (displaySales === "nb") {
    return <span>{cumulated ? value.nb_cumulated : value.nb}</span>;
  }
  if (displaySales === "total") {
    const total = cumulated ? value.total_cumulated : value.total;
    return (
      <span>
        {total.toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </span>
    );
  }
  if (displaySales === "per_prospect") {
    const per_prospect = cumulated
      ? value.per_prospect_cumulated
      : value.per_prospect;
    return (
      <span>
        {per_prospect.toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        })}
      </span>
    );
  }
  return <></>;
}

type MyOption = { value: string; label: string };

function stringArrayToOptions(arr: string[]): MyOption[] {
  return arr.map((d) => ({
    value: d,
    label: d,
  }));
}

function stringOptionsToArrayOfString(arr: MyOption[]): string[] {
  return arr.map((d) => d.value);
}

export function SelectAcquisitions({
  onLoad,
  selectedAcquisitions,
  setSelectedAcquisitions,
}: {
  onLoad: () => Promise<string[]>;
  selectedAcquisitions: string[];
  setSelectedAcquisitions: (elements: string[]) => void;
}) {
  const [acquisitions, setAcquisitions] = useState<MyOption[]>([]);

  useEffect(() => {
    onLoad().then((e) => {
      setAcquisitions(stringArrayToOptions(e));
    });
  }, []);

  return (
    <Select
      value={stringArrayToOptions(selectedAcquisitions)}
      options={acquisitions}
      isMulti
      closeMenuOnSelect={false}
      onChange={(newElements) => {
        setSelectedAcquisitions(
          stringOptionsToArrayOfString(newElements as MyOption[])
        );
      }}
      noOptionsMessage={() => null}
      isSearchable
      filterOption={(
        candidate: { label: string; value: string; data: any },
        input: string
      ) => {
        if (input) {
          return (
            fuzzysort.single(
              input.replace(/[^a-zA-Z0-9]/g, ""),
              candidate.label.replace(/[^a-zA-Z0-9]/g, "")
            ) !== null
          );
        }

        return true;
      }}
    />
  );
}

export default function CohortePage() {
  const [acquisitions, setAcquisitions] = useState<string[]>([]);
  const [cohorte, setCohorte] = useState<CohorteRow[]>([]);
  const [displaySales, setDisplaySales] = useState<DisplaySale>("nb");
  const [collectedOrdered, setCollectedOrdered] = useState<
    "ordered" | "collected"
  >("ordered");
  const [cumulated, setCumulated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState<Date | null>(
    DateTime.now().minus({ months: 11 }).startOf("month").toJSDate()
  );
  const [to, setTo] = useState<Date | null>(
    DateTime.now().endOf("month").toJSDate()
  );

  useEffect(() => {
    setIsLoading(true);
    http
      .post("/cohorte/", {
        range: toRange(from, to),
        amount_type: collectedOrdered,
        acquisitions,
      })
      .then((response) => {
        setCohorte(response.data as CohorteRow[]);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Page title="Cohorte">
      <div className="bg-white min-h-full">
        <div>
          <main className="mx-auto p-4 sm:p-6 lg:p-8">
            <section aria-labelledby="cohorte">
              <div className="grid md:grid-cols-2  xl:grid-cols-5 gap-4">
                <div>
                  <h2 className="text-sm leading-5 text-gray-500">Dates </h2>
                  <div className="grid gap-y-2 grid-rows-2">
                    <DatePicker
                      selected={from}
                      onChange={(date) => setFrom(date)}
                      selectsStart
                      startDate={from}
                      endDate={to}
                      showMonthYearPicker
                      dateFormat="MMMM yyyy"
                      locale="fr"
                      className="form-input block w-full sm:text-sm sm:leading-5 border border-gray-300 rounded-md mt-1"
                      placeholderText="Du"
                    />
                    <DatePicker
                      selected={to}
                      onChange={(date) => setTo(date)}
                      selectsEnd
                      startDate={from}
                      endDate={to}
                      showMonthYearPicker
                      dateFormat="MMMM yyyy"
                      locale="fr"
                      highlightDates={[new Date()]}
                      className="form-input block w-full sm:text-sm sm:leading-5 border border-gray-300 rounded-md mt-1"
                      placeholderText="A"
                    />
                  </div>
                </div>
                <div>
                  <h2 className="text-sm leading-5 text-gray-500">
                    Filtrer les acquisitions
                  </h2>
                  <div className="mt-1">
                    <SelectAcquisitions
                      onLoad={() =>
                        http
                          .get("/acquisitions/")
                          .then((response) => response.data as string[])
                      }
                      selectedAcquisitions={acquisitions}
                      setSelectedAcquisitions={setAcquisitions}
                    />
                  </div>
                </div>
                <div>
                  <h2 className="text-sm leading-5 text-gray-500">
                    Encaissé/Commandé
                  </h2>
                  <fieldset className="mt-4">
                    <div className="space-y-4 sm:flex sm:flex-col">
                      <div className="flex items-center">
                        <input
                          id="collected"
                          name="collected"
                          type="radio"
                          checked={collectedOrdered === "collected"}
                          onChange={(e) => setCollectedOrdered("collected")}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor="collected"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Encaissé
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="ordered"
                          name="ordered"
                          type="radio"
                          checked={collectedOrdered === "ordered"}
                          onChange={(e) => setCollectedOrdered("ordered")}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor="ordered"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Commandé
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div>
                  <h2 className="text-sm leading-5 text-gray-500">
                    Affichage des ventes par
                  </h2>
                  <fieldset className="mt-4">
                    <div className="space-y-4 sm:flex sm:flex-col">
                      <div className="flex items-center">
                        <input
                          id="nb"
                          name="nb"
                          type="radio"
                          checked={displaySales === "nb"}
                          onChange={(e) => setDisplaySales("nb")}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor="nb"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Nombre
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="total"
                          name="total"
                          type="radio"
                          checked={displaySales === "total"}
                          onChange={(e) => setDisplaySales("total")}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor="total"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Montant total
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="per_prospect"
                          name="per_prospect"
                          type="radio"
                          checked={displaySales === "per_prospect"}
                          onChange={(e) => setDisplaySales("per_prospect")}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor="per_prospect"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          €/Prospects
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div>
                  <h2 className="text-sm leading-5 text-gray-500">Cumulé</h2>
                  <div className="mt-4">
                    <Switch
                      checked={cumulated}
                      onChange={setCumulated}
                      className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <span
                        aria-hidden="true"
                        className="pointer-events-none absolute bg-white w-full h-full rounded-md"
                      />
                      <span
                        aria-hidden="true"
                        className={`${
                          cumulated ? "bg-blue-600" : "bg-gray-200"
                        } pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`}
                      />
                      <span
                        aria-hidden="true"
                        className={`${
                          cumulated ? "translate-x-5" : "translate-x-0"
                        } ointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
              <div className="py-4">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => {
                    setIsLoading(true);
                    http
                      .post("/cohorte/", {
                        range: toRange(from, to),
                        amount_type: collectedOrdered,
                        acquisitions,
                      })
                      .then((response) => {
                        setCohorte(response.data as CohorteRow[]);
                      })
                      .finally(() => setIsLoading(false));
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-65"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <UsersIcon className="-ml-1 mr-2 h-5 w-5" />
                  )}
                  Calculer la cohorte
                </button>
              </div>
              <div>
                <div className="lg:h-full text-xs overflow-x-scroll">
                  {!isLoading && (
                    <div className="py-5">
                      <table className="min-w-full bg-white">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-5 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Prospects
                            </th>
                            <th
                              scope="col"
                              className="px-5 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Mois
                            </th>
                            {cohorte.length > 0 &&
                              cohorte[0].sales.map((s, i) => (
                                <th
                                  key={i}
                                  scope="col"
                                  className="px-5 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  M{i + 1}
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {cohorte.map((c, i) => {
                            const month = DateTime.fromISO(c.month);
                            return (
                              <tr key={i}>
                                <td className="px-5 py-2 whitespace-nowrap text-sm text-gray-500">
                                  {c.acquisitions}
                                </td>
                                <td className="px-5 py-2 whitespace-nowrap text-sm text-gray-500">
                                  {month.toLocaleString({
                                    month: "long",
                                    year: "numeric",
                                  })}
                                </td>
                                {c.sales.map((sale, j) => (
                                  <td
                                    key={j}
                                    className="px-5 py-2 whitespace-nowrap text-right text-sm text-gray-500"
                                  >
                                    {month.plus({ months: j }) >
                                    DateTime.now().endOf("month") ? null : (
                                      <CohorteValue
                                        value={sale}
                                        cumulated={cumulated}
                                        displaySales={displaySales}
                                      />
                                    )}
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </Page>
  );
}
