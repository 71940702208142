import { DateTime } from "luxon";
import { Sale } from "./types";

export function sortSalesByDate(s1: Sale, s2: Sale) {
  return (
    DateTime.fromISO(s2.sale_date).toMillis() -
    DateTime.fromISO(s1.sale_date).toMillis()
  );
}

export function toIsoDateOrNull(date: Date | null) {
  return date === null ? null : DateTime.fromJSDate(date).toISODate();
}


export function parseDate(d: string | null): Date | null {
  if (!d) return null;
  return DateTime.fromISO(d).toJSDate();
}