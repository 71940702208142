import { useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { DateTime } from "luxon";

export type RangeOptionValue =
  | "previousWeek"
  | "previousMonth"
  | "previousQuarter"
  | "lastMonth"
  | "lastYear"
  | "yesterday"
  | "today"
  | "currentWeek"
  | "currentMonth"
  | "currentQuarter"
  | "currentYear"
  | "last30Days"
  | "last90Days"
  | "last365Days"
  | "sinceLastFriday"
  | "sinceLastThursday"
  | "all"
  | "custom";

export default function SelectWithRange({
  id,
  value,
  setValue,
  startDate = null,
  setStartDate,
  endDate = null,
  setEndDate,
}: {
  id: string;
  value: RangeOptionValue;
  setValue: (option: RangeOptionValue) => void;
  startDate: Date | null;
  setStartDate: (d: Date | null) => void;
  endDate: Date | null;
  setEndDate: (d: Date | null) => void;
}) {
  const labels: { [k in RangeOptionValue]: string } = {
    sinceLastThursday: "Depuis jeudi dernier",
    previousWeek: "Semaine dernière",
    currentWeek: "Semaine en cours",
    custom: "Définir la période manuellement",
    previousMonth: "Mois dernier",
    previousQuarter: "Trimestre dernier",
    lastMonth: "Dernier mois",
    lastYear: "Année dernière",
    yesterday: "Hier",
    today: "Aujourd'hui",
    currentMonth: "Mois en cours",
    currentQuarter: "Trimestre courant",
    currentYear: "Cumul annuel en cours",
    last30Days: "30 derniers jours",
    last90Days: "90 derniers jours",
    last365Days: "365 derniers jours",
    sinceLastFriday: "Depuis vendredi dernier",
    all: "Depuis le début",
  };
  const label = labels[value];
  const seeDatePicker = value === "custom";

  useEffect(() => {
    const today = DateTime.now();
    const yesterday = today.minus({
      day: 1,
    });
    if (value === "today") {
      setStartDate(today.startOf("day").toJSDate());
      setEndDate(today.endOf("day").toJSDate());
    }
    if (value === "currentWeek") {
      setStartDate(today.startOf("week").toJSDate());
      setEndDate(today.endOf("week").toJSDate());
    }
    if (value === "currentMonth") {
      setStartDate(today.startOf("month").toJSDate());
      setEndDate(today.endOf("month").toJSDate());
    }
    if (value === "currentQuarter") {
      setStartDate(today.startOf("quarter").toJSDate());
      setEndDate(today.endOf("quarter").toJSDate());
    }
    if (value === "currentYear") {
      setStartDate(today.startOf("year").toJSDate());
      setEndDate(today.endOf("year").toJSDate());
    }
    if (value === "yesterday") {
      setStartDate(yesterday.startOf("day").toJSDate());
      setEndDate(yesterday.endOf("day").toJSDate());
    }
    if (value === "previousWeek") {
      const lastWeek = today.minus({
        week: 1,
      });
      setStartDate(lastWeek.startOf("week").toJSDate());
      setEndDate(lastWeek.endOf("week").toJSDate());
    }
    if (value === "previousMonth") {
      const lastMonth = today.minus({
        month: 1,
      });
      setStartDate(lastMonth.startOf("month").toJSDate());
      setEndDate(lastMonth.endOf("month").toJSDate());
    }
    if (value === "previousQuarter") {
      const lastQuarter = today.minus({
        quarter: 1,
      });
      setStartDate(lastQuarter.startOf("quarter").toJSDate());
      setEndDate(lastQuarter.endOf("quarter").toJSDate());
    }
    if (value === "lastMonth") {
      const lastMonth = today.minus({
        month: 1,
      });
      setStartDate(lastMonth.startOf("month").toJSDate());
      setEndDate(lastMonth.endOf("month").toJSDate());
    }
    if (value === "lastYear") {
      const lastYear = today.minus({
        year: 1,
      });
      setStartDate(lastYear.startOf("year").toJSDate());
      setEndDate(lastYear.endOf("year").toJSDate());
    }
    if (value === "last30Days") {
      const last30Days = today.minus({
        days: 30,
      });
      setStartDate(last30Days.startOf("day").toJSDate());
      setEndDate(yesterday.endOf("day").toJSDate());
    }
    if (value === "last90Days") {
      const last90Days = today.minus({
        days: 90,
      });
      setStartDate(last90Days.startOf("day").toJSDate());
      setEndDate(yesterday.endOf("day").toJSDate());
    }
    if (value === "last365Days") {
      const last365Days = today.minus({
        days: 365,
      });
      setStartDate(last365Days.startOf("day").toJSDate());
      setEndDate(yesterday.endOf("day").toJSDate());
    }
    if (value === "sinceLastThursday") {
      const previousThursday = today.set({ weekday: 4 }).minus({
        days: 7,
      });
      setStartDate(previousThursday.startOf("day").toJSDate());
      setEndDate(today.toJSDate());
    }
    if (value === "sinceLastFriday") {
      const previousFriday = today.set({ weekday: 5 }).minus({
        days: 7,
      });
      setStartDate(previousFriday.startOf("day").toJSDate());
      setEndDate(today.toJSDate());
    }
    if (value === "all") {
      setStartDate(null);
      setEndDate(null);
    }
  }, [value, setStartDate, setEndDate]);

  return (
    <div>
      <div>
        <Select
          id={id}
          value={{ value, label }}
          options={(Object.keys(labels) as Array<RangeOptionValue>).map(
            (value) => ({ value, label: labels[value] })
          )}
          isClearable={true}
          onChange={(choice) => {
            if (!choice) {
              setValue("all");
              return;
            }
            setValue(choice.value);
          }}
        />
      </div>
      {seeDatePicker && (
        <div className="grid gap-x-2 grid-cols-2">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            locale="fr"
            className="form-input block w-full sm:text-sm sm:leading-5 border border-gray-300 rounded-md mt-1"
            placeholderText="Du"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            locale="fr"
            highlightDates={[new Date()]}
            className="form-input block w-full sm:text-sm sm:leading-5 border border-gray-300 rounded-md mt-1"
            placeholderText="A"
          />
        </div>
      )}
    </div>
  );
}
