export default function Page404() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h1>Page non trouvée</h1>
        <p>Erreur 404</p>
        <p>Nous recherchons votre page... mais nous ne la trouvons pas.</p>
      </div>
    </div>
  );
}
