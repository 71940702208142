import { useEffect, useState } from "react";
import http from "./services/http";
import {
  ChartBarSquareIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline'
import Page from "./layout/Page";
import { Link, useLocation, useNavigate } from "react-router-dom";
import copy from "copy-text-to-clipboard";
import { ISODateToHuman } from "./ProspectPage";
import { Sale } from "./types";
import { parseDate, sortSalesByDate, toIsoDateOrNull } from "./date";
import qs from "qs";
import { Switch } from "@headlessui/react";
import SelectWithRangeAndSelectAll from "./components/SelectWithRangeAndSelectAll";

interface Stats {
  nb_prospects: number;
  nb_sales: number;
  total_amount: number;
  total_amount_ordered: number;
  percentage_sales: number;
  euros_per_propect: number;
  euros_per_propect_ordered: number;
  sales: Sale[];
}

type Acquisition = string;
type Sequence = string;
type Product = string;

export function StatLine({
  sale,
  seeOrdered,
}: {
  sale: Sale;
  seeOrdered: boolean;
}) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copied]);

  const CustomClipboardIcon = copied ? ClipboardDocumentCheckIcon : ClipboardDocumentIcon;
  const amount = seeOrdered ? sale.amount_ordered : sale.amount;
  return (
    <tr key={sale.email}>
      <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
        {ISODateToHuman(sale.acq_date)}
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
        {ISODateToHuman(sale.seq_date)}
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
        {ISODateToHuman(sale.sale_date)}
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
        <div className="flex items-center gap-1">
          <Link
            to={`/prospect?email=${sale.email}`}
            className="text-blue-500 hover:underline flex items-center gap-1"
          >
            {sale.email}
          </Link>
          <CustomClipboardIcon
            className="h-5 w-5 text-gray-400 hover:text-gray-500 cursor-pointer"
            aria-hidden="true"
            onClick={() => {
              copy(sale.email);
              setCopied(true);
            }}
          />
        </div>
      </td>
      <td
        className={`px-4 py-4 whitespace-nowrap text-sm text-gray-500 ${
          amount <= 0 ? "text-red-500" : ""
        } text-center`}
      >
        {amount.toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        })}
      </td>
      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
        {sale.product}
      </td>
    </tr>
  );
}

function buildParams({
  acquisitions,
  acquisitionsFrom,
  acquisitionsTo,
  sequences,
  sequencesFrom,
  sequencesTo,
  products,
  productsFrom,
  productsTo,
  seeRefund,
  seeOrdered,
}: {
  acquisitions: string[];
  acquisitionsFrom: Date | null;
  acquisitionsTo: Date | null;
  sequences: string[];
  sequencesFrom: Date | null;
  sequencesTo: Date | null;
  products: string[];
  productsFrom: Date | null;
  productsTo: Date | null;
  seeRefund: boolean;
  seeOrdered: boolean;
}) {
  return {
    acquisitions,
    sequences,
    products,
    acquisitions_from: toIsoDateOrNull(acquisitionsFrom),
    acquisitions_to: toIsoDateOrNull(acquisitionsTo),
    sequences_from: toIsoDateOrNull(sequencesFrom),
    sequences_to: toIsoDateOrNull(sequencesTo),
    products_from: toIsoDateOrNull(productsFrom),
    products_to: toIsoDateOrNull(productsTo),
    with_refund: seeRefund,
    ordered: seeOrdered,
  };
}

export default function PageVentes() {
  const navigate = useNavigate();
  const location = useLocation();
  const filters = {
    acquisitions: [],
    acquisitions_from: null,
    acquisitions_to: null,
    sequences: [],
    sequences_from: null,
    sequences_to: null,
    products: [],
    products_from: null,
    products_to: null,
    with_refund: "true",
    ordered: "true",
    ...qs.parse(location.search, { ignoreQueryPrefix: true }),
  };
  const [acquisitions, setAcquisitions] = useState<Acquisition[]>(
    filters.acquisitions
  );
  const [seeOrdered, setSeeOrdered] = useState(filters.ordered === "true");
  const [seeRefund, setSeeRefund] = useState(filters.with_refund === "true");
  const [acquisitionsFrom, setAcquisitionsFrom] = useState<Date | null>(
    parseDate(filters.acquisitions_from)
  );
  const [acquisitionsTo, setAcquisitionsTo] = useState<Date | null>(
    parseDate(filters.acquisitions_to)
  );

  const [sequences, setSequences] = useState<Sequence[]>(filters.sequences);
  const [sequencesFrom, setSequencesFrom] = useState<Date | null>(
    parseDate(filters.sequences_from)
  );
  const [sequencesTo, setSequencesTo] = useState<Date | null>(
    parseDate(filters.sequences_to)
  );

  const [products, setProducts] = useState<Product[]>(filters.products);
  const [productsFrom, setProductsFrom] = useState<Date | null>(
    parseDate(filters.products_from)
  );
  const [productsTo, setProductsTo] = useState<Date | null>(
    parseDate(filters.products_to)
  );

  const [stats, setStats] = useState<Stats | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [seeAllSales, setSeeAllSales] = useState(false);
  const maxSales = 50;
  useEffect(() => {
    const params = buildParams({
      acquisitions,
      sequences,
      products,
      acquisitionsFrom,
      acquisitionsTo,
      sequencesFrom,
      sequencesTo,
      productsFrom,
      productsTo,
      seeRefund,
      seeOrdered,
    });
    navigate(
      `/?${qs.stringify(params, {
        arrayFormat: "brackets",
        skipNulls: true,
        encode: false,
      })}`
    );
  }, [
    acquisitions,
    sequences,
    products,
    acquisitionsFrom,
    acquisitionsTo,
    sequencesFrom,
    sequencesTo,
    productsFrom,
    productsTo,
    seeRefund,
    seeOrdered,
  ]);

  const sales =
    stats === null
      ? []
      : stats.sales.sort(sortSalesByDate).filter((sale) => {
          if (seeAllSales) return true;
          return seeOrdered ? sale.amount_ordered !== 0 : sale.amount !== 0;
        });

  return (
    <Page title="ROI Hunter">
      <div className="px-4 py-5 sm:p-0">
        <div className="p-5 grid gap-x-4 grid-cols-3 bg-white">
          <div>
            <SelectWithRangeAndSelectAll
              label="Acquisitions"
              onLoad={() =>
                http
                  .get("/acquisitions/")
                  .then((response) => response.data as Acquisition[])
              }
              selectedElements={acquisitions}
              setSelectedElements={setAcquisitions}
              startDate={acquisitionsFrom}
              setStartDate={setAcquisitionsFrom}
              endDate={acquisitionsTo}
              setEndDate={setAcquisitionsTo}
            />
          </div>
          <div>
            <SelectWithRangeAndSelectAll
              label="Séquences"
              onLoad={() =>
                http
                  .get("/sequences/")
                  .then((response) => response.data as Sequence[])
              }
              selectedElements={sequences}
              setSelectedElements={setSequences}
              startDate={sequencesFrom}
              setStartDate={setSequencesFrom}
              endDate={sequencesTo}
              setEndDate={setSequencesTo}
            />
          </div>
          <div>
            <SelectWithRangeAndSelectAll
              label="Produits"
              onLoad={() =>
                http
                  .get("/sales/")
                  .then((response) => response.data as Product[])
              }
              selectedElements={products}
              setSelectedElements={setProducts}
              startDate={productsFrom}
              setStartDate={setProductsFrom}
              endDate={productsTo}
              setEndDate={setProductsTo}
            />
            <div className="mt-2">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={seeRefund}
                  onChange={setSeeRefund}
                  className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <span
                    aria-hidden="true"
                    className="pointer-events-none absolute bg-white w-full h-full rounded-md"
                  />
                  <span
                    aria-hidden="true"
                    className={`${
                      seeRefund ? "bg-blue-600" : "bg-gray-200"
                    } pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`}
                  />
                  <span
                    aria-hidden="true"
                    className={`${
                      seeRefund ? "translate-x-5" : "translate-x-0"
                    } ointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-xs text-gray-500">
                    Voir les remboursements
                  </span>
                </Switch.Label>
              </Switch.Group>
            </div>
            <div className="mt-2">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={seeOrdered}
                  onChange={setSeeOrdered}
                  className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <span
                    aria-hidden="true"
                    className="pointer-events-none absolute bg-white w-full h-full rounded-md"
                  />
                  <span
                    aria-hidden="true"
                    className={`${
                      seeOrdered ? "bg-blue-600" : "bg-gray-200"
                    } pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`}
                  />
                  <span
                    aria-hidden="true"
                    className={`${
                      seeOrdered ? "translate-x-5" : "translate-x-0"
                    } ointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-xs text-gray-500">
                    {seeOrdered ? "commandé" : "encaissé"}
                  </span>
                </Switch.Label>
              </Switch.Group>
            </div>
          </div>
          <div className="mt-4">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                const params = buildParams({
                  acquisitions,
                  sequences,
                  products,
                  acquisitionsFrom,
                  acquisitionsTo,
                  sequencesFrom,
                  sequencesTo,
                  productsFrom,
                  productsTo,
                  seeRefund,
                  seeOrdered,
                });
                setIsLoading(true);
                http
                  .get("/stats/", {
                    params,
                    paramsSerializer: (params) =>
                      qs.stringify(params, {
                        skipNulls: true,
                        arrayFormat: "repeat",
                      }),
                  })
                  .then((response) => {
                    setStats(response.data);
                  })
                  .finally(() => setIsLoading(false));
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  className="animate-spin -ml-1 mr-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-65"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <ChartBarSquareIcon className="-ml-1 mr-2 h-5 w-5" />
              )}
              Récupérer les données
            </button>
          </div>
        </div>
        <div className="mt-5">
          {stats && (
            <table className="min-w-full bg-white">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Prospects
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Ventes
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {seeOrdered ? "Commandé" : "Encaissé"}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Pourcentage
                    <br />
                    convertion
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Euros par
                    <br />
                    prospects
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {stats.nb_prospects}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {stats.nb_sales}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {seeOrdered
                      ? stats.total_amount_ordered.toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })
                      : stats.total_amount.toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span title={stats.percentage_sales.toString()}>
                      {stats.percentage_sales.toFixed(2) + "%"}
                    </span>
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                    {seeOrdered ? (
                      <span title={stats.euros_per_propect_ordered.toString()}>
                        {stats.euros_per_propect_ordered.toLocaleString(
                          "fr-FR",
                          {
                            style: "currency",
                            currency: "EUR",
                          }
                        )}
                      </span>
                    ) : (
                      <span title={stats.euros_per_propect.toString()}>
                        {stats.euros_per_propect.toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className="mt-5">
          {stats && (
            <table className="min-w-full bg-white">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Acquisition
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Entrée séquence
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Achat
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
                  >
                    {seeOrdered ? "Commandé" : "Encaissé"}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Produit
                  </th>
                </tr>
              </thead>
              <tbody>
                {sales
                  .filter((_, i) => {
                    if (seeAllSales) return true;
                    return i < maxSales;
                  })
                  .map((sale, i) => (
                    <StatLine key={i} sale={sale} seeOrdered={seeOrdered} />
                  ))}
              </tbody>
              {sales.length > maxSales && !seeAllSales && (
                <tbody>
                  <tr>
                    <td colSpan={100} className="text-center py-10">
                      <button
                        className="text-blue-500"
                        onClick={() => setSeeAllSales(true)}
                      >
                        Voir toutes les ventes
                      </button>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          )}
        </div>
      </div>
    </Page>
  );
}
