const TOKEN = "ROIHUNTER";

export function getToken(): string | null {
  const token = localStorage.getItem(TOKEN);
  if (!token) return null;
  return token;
}

export function setToken(value: string) {
  localStorage.setItem(TOKEN, value);
}

export function removeToken() {
  localStorage.removeItem(TOKEN);
}
